import React from "react";

import Modal from "@mui/material/Modal";
import { Box } from "@mui/system";

import { Alert, AlertTitle, Grid, Typography } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import LaunchIcon from "@mui/icons-material/Launch";

import * as ApiCall from "../../../../../API/ApiCalling";
import Button from "@mui/material/Button";
import { useSnapshot } from "valtio";
import { widState } from "../../../store/workdriveState";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  maxWidth: "1100px",
  width: "100%",
  height: "100vh",
  transform: "translate(-50%, -50%)",
  outline: "none",
  background: "#ffffff",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: 20,
};

function ModalFile({
  open,
  setOpen,
  profileImage,
  file,
  settingId,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl,
  driveId,
}) {
  const snap = useSnapshot(widState);

  const handleClose = () => {
    snap?.setLoading(false);
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <img src="./No_file_preview.png" width={200} />
        <Typography sx={{ fontWeight: "bold", mb: 0.8 }}>
          Preview is not supported
        </Typography>
        <Typography sx={{ color: "#8a8a8a", fontSize: 15, mb: 1.7 }}>
          Please download the file or open in SharePoint
        </Typography>
        <Grid item>
          <Button
            size="small"
            variant="outlined"
            sx={{ m: 1, width: 180 }}
            onClick={async () => {
              snap.setLoading(false);
              setOpen(false);
            }}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            size="small"
            sx={{ m: 1, px: 2.5 }}
            onClick={() => {
              // BUG fix this
              window.open(`${file?.web_url}`, "_blank", "noreferrer");
            }}
          >
            <LaunchIcon sx={{ fontSize: 16, mr: 1 }} />
            Open in SharePoint
          </Button>

          <Button
            variant="contained"
            size="small"
            sx={{
              m: 1,
              width: 180,
              bgcolor: "#1976d2",
              "&:hover": { bgcolor: "#1466b8" },
            }}
            onClick={async () => {
              try {
                snap.setLoading(true);
                setOpen(false);
                snap?.setLoading(true);
                setOpen(false);
                let response = await ApiCall.getImageResponse(
                  file,
                  connname,
                  orgid,
                  apikey,
                  datacenterurl,
                  settingUrl,
                  driveId
                );

                let url = "";
                if (file?.type == "spreadsheet" || file?.type == "zohosheet") {
                  url = window.URL.createObjectURL(
                    new Blob([response.data], {
                      type: "text/csv;charset=utf-8",
                    })
                  );
                } else {
                  url = window.URL.createObjectURL(new Blob([response.data]));
                }

                // const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", file?.name); //or any other extension
                document.body.appendChild(link);
                snap?.setLoading(false);
                link.click();
              } catch (error) {
                snap.setSnackbar({
                  bool: true,
                  type: "error",
                  message: "Error!! Couldn't download file",
                });

                snap?.setLoading(false);
                setOpen(false);
              }
            }}
          >
            <ArrowDownwardIcon sx={{ fontSize: 16, mr: 1 }} />
            Download
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
}

export default ModalFile;
